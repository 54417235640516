import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { type ApiRoute } from "~/hooks/useApi";
import { api } from "~/lib/api";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { createQuery } from "~/lib/reactQuery";
import { type AuthenticatedUser } from "~/lib/session";
import { AuthenticatedUserIncludes } from "~/services/auth/authenticatedUserIncludes";

const handler: NextApiHandler<AuthenticatedUser["subsidiaries"][number]> = async (req, res) => {
  const masterUser = await req.prisma.user.findUniqueOrThrow({
    where: { id: getRequiredUser(req).id },
    select: { company: AuthenticatedUserIncludes["subsidiaries"] },
  });

  res.status(HttpStatus.OK).json(masterUser.company);
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const FETCH_MASTER_USER_ROOT_COMPANY_QUERY_KEY: ApiRoute["pathname"] =
  "/api/auth/company-impersonation/fetch-master-user-root-company";

export const useFetchMasterUserRootCompanyQuery = createQuery<typeof handler>({
  path: FETCH_MASTER_USER_ROOT_COMPANY_QUERY_KEY,
});
