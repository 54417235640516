import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { broadcastTabSyncingEvent } from "~/hooks/useTabSyncing";
import { api } from "~/lib/api";
import { handleExceptionalRightsChange } from "~/lib/exceptionalRightsChange";
import { createMutation } from "~/lib/reactQuery";
import { stopCompanyImpersonation } from "~/lib/session";

const handler: NextApiHandler = async (req, res) => {
  await stopCompanyImpersonation(req);

  res.status(HttpStatus.OK).json({
    message: "Impersonation stopped",
  });
};

export default api(handler, {
  method: "POST",
  authentication: { superAdmin: true },
});

export const useStopCompanyImpersonationMutation = createMutation<typeof handler>({
  path: "/api/auth/company-impersonation/stop-company-impersonation",
  options: () => ({
    onSuccess: () => {
      broadcastTabSyncingEvent({
        event: "company-impersonation",
        payload: { companyId: null },
      });

      handleExceptionalRightsChange();
    },
  }),
});
