import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object } from "yup";
import { broadcastTabSyncingEvent } from "~/hooks/useTabSyncing";
import { api } from "~/lib/api";
import { handleExceptionalRightsChange } from "~/lib/exceptionalRightsChange";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { createMutation } from "~/lib/reactQuery";
import { impersonateSubsidiary, stopCompanyImpersonation } from "~/lib/session";
import { type YupOutputType } from "~/lib/utils";
import { verifySubsidiaryImpersonationAllowed } from "~/services/auth/verifySubsidiaryImpersonationAllowed";

export const ImpersonateSubsidiarySchema = object({
  subsidiaryId: number().required(),
});

export type ImpersonateSubsidiaryInput = YupOutputType<typeof ImpersonateSubsidiarySchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = ImpersonateSubsidiarySchema.validateSync(req.body, { abortEarly: false });
  const user = getRequiredUser(req);

  const originalUser = await req.prisma.user.findUniqueOrThrow({
    where: { id: user.id },
    select: { companyId: true },
  });

  // Prevent considering root company as subsidiary
  if (originalUser.companyId !== input.subsidiaryId) {
    await verifySubsidiaryImpersonationAllowed(req, input);
    await impersonateSubsidiary(req, input);
  } else {
    await stopCompanyImpersonation(req);
  }

  res.status(HttpStatus.OK).json({ message: "OK" });
};

export default api(handler, {
  method: "POST",
  authentication: { canAccessSubsidiaries: true },
});

export const useImpersonateSubsidiaryMutation = createMutation<typeof handler, typeof ImpersonateSubsidiarySchema>({
  path: "/api/auth/company-impersonation/impersonate-subsidiary",
  schema: ImpersonateSubsidiarySchema,
  options: () => ({
    onSuccess: (_, variables) => {
      broadcastTabSyncingEvent({
        event: "company-impersonation",
        payload: { companyId: variables.subsidiaryId },
      });

      handleExceptionalRightsChange();
    },
  }),
});
