import { ButtonGroup, Stack, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { useIsUserNoticesDisplayed } from "~/components/layout/UserNotices";
import { Button } from "~/components/ui/core/Button";
import { Image } from "~/components/ui/core/Image";
import { Modal } from "~/components/ui/core/Modal";
import { useFeatureFlags } from "~/hooks/useFeatureFlags";
import { useSession } from "~/hooks/useSession";
import { makePublicAssetUrl } from "~/lib/files/makePublicAssetUrl";
import { useI18n } from "~/lib/i18n/useI18n";
import { type AuthenticatedUser } from "~/lib/session";
import { useFetchMasterUserRootCompanyQuery } from "~/pages/api/auth/company-impersonation/fetch-master-user-root-company";
import { useImpersonateSubsidiaryMutation } from "~/pages/api/auth/company-impersonation/impersonate-subsidiary";

type Props = {
  className?: string;
};

export const useIsSubsidiariesNoticeDisplayed = () => {
  const { user } = useSession();
  const { CAN_ACCESS_SUBSIDIARIES } = useFeatureFlags();
  const { isUserNoticesDisplayed } = useIsUserNoticesDisplayed();
  const userHasSubsidiaries = user?.subsidiaries.length ?? 0 > 0;

  return { isSubsidiariesNoticeDisplayed: CAN_ACCESS_SUBSIDIARIES && userHasSubsidiaries && !isUserNoticesDisplayed };
};

export const SubsidiariesNotice: React.FC<Props> = ({ className }) => {
  const { t } = useI18n();
  const { user } = useSession();
  const { isSubsidiariesNoticeDisplayed } = useIsSubsidiariesNoticeDisplayed();
  const [isChangeSubsidiaryModalOpen, setIsChangeSubsidiaryModalOpen] = useState(false);
  const fetchMasterUserRootCompanyQuery = useFetchMasterUserRootCompanyQuery({
    options: { enabled: !!user },
  });

  if (!user || !isSubsidiariesNoticeDisplayed) {
    return null;
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        className={classNames(className, "bg-blue-500 px-4 text-white")}
        spacing={2}
      >
        <Typography variant="h4" color="white">
          {t("components.layout.subsidiaries-notice.you-are-using-figures-for", { companyName: user.company.name })}
        </Typography>

        <Typography
          className="cursor-pointer underline"
          variant="h4"
          color="white"
          onClick={() => setIsChangeSubsidiaryModalOpen(true)}
        >
          Change company
        </Typography>
      </Stack>

      <Modal
        className="w-[560px]"
        isOpen={isChangeSubsidiaryModalOpen}
        title={t("components.layout.subsidiaries-notice.change-company.title")}
        onClose={() => setIsChangeSubsidiaryModalOpen(false)}
      >
        <Stack alignItems="center" justifyContent="center" className="w-full pt-4 pb-2">
          <ButtonGroup orientation="vertical" size="large">
            {fetchMasterUserRootCompanyQuery.data && <CompanyButton company={fetchMasterUserRootCompanyQuery.data} />}

            {user.subsidiaries.map((subsidiary) => (
              <CompanyButton key={subsidiary.id} company={subsidiary} />
            ))}
          </ButtonGroup>
        </Stack>
      </Modal>
    </>
  );
};

const COMPANY_BUTTON_LOGO_SIZE = 24;

const CompanyButton: React.FC<{ company: AuthenticatedUser["subsidiaries"][number] }> = ({ company }) => {
  const { getRequiredUser } = useSession();
  const user = getRequiredUser();
  const impersonateSubsidiaryMutation = useImpersonateSubsidiaryMutation();

  return (
    <Button
      key={company.id}
      size="large"
      disabled={user.companyId === company.id}
      isLoading={impersonateSubsidiaryMutation.isLoading}
      onClick={() => impersonateSubsidiaryMutation.mutateAsync({ subsidiaryId: company.id })}
    >
      <Stack spacing={2} direction="row" alignItems="center" className="w-full">
        {company.logo && (
          <Image
            alt="company logo"
            className="rounded"
            containerClassName="rounded"
            height={(COMPANY_BUTTON_LOGO_SIZE * company.logo.height) / company.logo.width}
            src={makePublicAssetUrl(company.logo.path)}
            width={COMPANY_BUTTON_LOGO_SIZE}
          />
        )}

        <Typography className={classNames({ "!ml-[33px]": !company.logo })}>{company.name}</Typography>
      </Stack>
    </Button>
  );
};
