import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { broadcastTabSyncingEvent } from "~/hooks/useTabSyncing";
import { api } from "~/lib/api";
import { handleExceptionalRightsChange } from "~/lib/exceptionalRightsChange";
import { createMutation } from "~/lib/reactQuery";
import { stopUserImpersonation } from "~/lib/session";

const handler: NextApiHandler = async (req, res) => {
  await stopUserImpersonation(req);

  res.status(HttpStatus.OK).json({
    message: "User impersonation stopped",
  });
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const useStopUserImpersonationMutation = createMutation<typeof handler>({
  path: "/api/auth/user-impersonation/stop-user-impersonation",
  options: () => ({
    onSuccess: () => {
      broadcastTabSyncingEvent({
        event: "user-impersonation",
        payload: { userId: null },
      });

      handleExceptionalRightsChange();
    },
  }),
});
