import { type AppContext } from "~/lib/context";
import { BusinessLogicError } from "~/lib/errors/businessLogicError";
import { getRequiredUser } from "~/lib/getRequiredUser";
import { logError } from "~/lib/logger";
import { type ImpersonateSubsidiaryInput } from "~/pages/api/auth/company-impersonation/impersonate-subsidiary";

export const verifySubsidiaryImpersonationAllowed = async (ctx: AppContext, input: ImpersonateSubsidiaryInput) => {
  const user = getRequiredUser(ctx);

  const masterUser = await ctx.prisma.user.findUnique({
    where: {
      id: user.id,
      subsidiaries: { some: { id: input.subsidiaryId } },
    },
    select: { id: true },
  });

  const rootCompany = await ctx.prisma.company.findUnique({
    where: {
      id: input.subsidiaryId,
      users: { some: { id: user.id } },
    },
    select: { id: true },
  });

  if (!masterUser && !rootCompany) {
    logError(ctx, "[impersonate-subsidiary] Failed attempt to impersonate subsidiary", {
      userId: user.id,
      subsidiaryId: input.subsidiaryId,
    });

    throw new BusinessLogicError("You are not allowed to perform this action");
  }
};
